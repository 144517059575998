import { Alergens } from "./Alergens"

export const MainDishes = () => {
  return (
    <>
      <h1 className="menu-category-heading">Оставете се на симфонията от вкусове
        да ви прегърне с нашите основни ястия</h1>
      <section className="menu-category">
        <article className="menu-item">
          <h2 className="name">ПИЛЕ ПАРМИДЖАНО</h2>
          <p className="description">
            Пилешки филенца запечени с пармезан,
            сметанено ризото, свежа салата
          </p>
          <p className="information">
            <span className="alergens">/ 7 /</span>
            <span className="price">350 гр. / 15.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ПИЛЕ ЧЕДЪР</h2>
          <p className="description">
            Пилешки филенца, чедър сос, спаначенокартофено пюре
          </p>
          <p className="information">
            <span className="alergens">/ 7, 9, 12 /</span>
            <span className="price">350 гр. / 15.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ПИЛЕШКИ ШИШЧЕТА</h2>
          <p className="description">
            Пилешки шишчета, картофено пюре,
            дзадзики
          </p>
          <p className="information">
            <span className="alergens">/ 7, 11 /</span>
            <span className="price">350 гр. / 15.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">РЕБРА АМЕРИКАНО</h2>
          <p className="description">
            Бавно готвени свински ребърца с барбекю сос,
            колсло, пикантни кънтри картофки
          </p>
          <p className="information">
            <span className="alergens">/ 6, 7, 9 /</span>
            <span className="price">500 гр. / 20.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">СВИНСКИ КАРЕТА</h2>
          <p className="description">
            Крехки свински карета, мачкани
            картофи със сирене, лютеница
          </p>
          <p className="information">
            <span className="alergens">/ 7 /</span>
            <span className="price">350 гр. / 17.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ТЕЛЕШКИ МЕДАЛЬОНИ</h2>
          <p className="description">
            Телешко бон филе, гъбено ризото, пресен
            лук, пепър сос, песто
          </p>
          <p className="information">
            <span className="alergens">/2, 7, 9, 12/</span>
            <span className="price">350 гр. / 26.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ДЖОЛАНЪТ НА АЛИНЕА</h2>
          <p className="description">
            Бавно готвен телешки джолан, дзадзики,
            спаначено картофено пюре
          </p>
          <p className="information">
            <span className="alergens">/ 7, 12, 14 /</span>
            <span className="price">400 гр. / 24.00 лв.</span>
          </p>
        </article>

        <article className="menu-item">
          <h2 className="name">КЛАСИЧЕСКИ ЧИЙЗБУРГЕР</h2>
          <p className="description">
            Телешко кюфте, питка бриош, розов домат,
            червен лук, чедър, айсберг, барбекю сос,
            пържени картофи
          </p>
          <p className="information">
            <span className="alergens">/ 7, 8, 9 /</span>
            <span className="price">400 гр. / 15.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ПИЛЕШКИ БУРГЕР</h2>
          <p className="description">
            Пилешко филе, питка бриош, чедър сос, кисели
            краставички, червен лук, айсберг, пържени
            картофи, билкова майонеза
          </p>
          <p className="information">
            <span className="alergens">/ 6, 7, 8 /</span>
            <span className="price">400 гр. / 15.00 лв.</span>
          </p>
        </article>

        <article className="menu-item">
          <h2 className="name">ФИЛЕ ОТ ЛАВРАК</h2>
          <p className="description">
            Филе от лаврак, лимонено ризото, печени
            зеленчуци, копър, сос Холандез
          </p>
          <p className="information">
            <span className="alergens">/ 5, 6, 7, 12 /</span>
            <span className="price">350 гр. / 20.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">СЬОМГА НА ПЛОЧА</h2>
          <p className="description">
            Филе от сьомга, лимонено ризото, пресен
            лук, сос тартар
          </p>
          <p className="information">
            <span className="alergens">/ 5, 7, 12 /</span>
            <span className="price">330 гр. / 24.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ХРУПКАВА БЯЛА РИБА</h2>
          <p className="description">
            Филе от бяла риба тилапия, панко
            японска панировка, пържени картофи, сос
            тартар
          </p>
          <p className="information">
            <span className="alergens">/5, 6, 7, 8, 12/</span>
            <span className="price">330 гр. / 18.00 лв.</span>
          </p>
        </article>
      </section>
      <Alergens />
    </>
  )
}