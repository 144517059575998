import { Alergens } from "./Alergens";

export const PastaAndRizoto = () => {
    return (
        <>
            <h1 className="menu-category-heading">Храната, споделена с любими хора, е в основата на италианската кухня!
            </h1>
            <section className="menu-category">
                <article className="menu-item">
                    <h2 className="name">ПАСТА КАПРЕЗЕ</h2>
                    <p className="description">
                        Лингуини, доматен сос, моцарела, чери
                        домати, пармезан, песто
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2, 7, 8 /</span>
                        <span className="price">400 гр. / 13.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПАСТА ПИЛЕШКО И СПАНАК</h2>
                    <p className="description">
                        Лингуини, сметана, пилешко филе,
                        спанак, маскарпоне
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">400 гр. / 14.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПАСТА ПЕПЕРОНИ</h2>
                    <p className="description">
                        Лингуини, сметана, пеперони, сушени
                        домати, пресен лук, пармезан
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">400 гр. / 15.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПАСТА БОЛОНЕЗЕ
                    </h2>
                    <p className="description">
                        Лингуини, телешка кайма, лук, моркови,
                        доматен сос, пармезан, песто
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2, 7, 8 /</span>
                        <span className="price">400 гр. / 14.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">РИЗОТО С ПИЛЕ И ГЪБИ</h2>
                    <p className="description">
                        Ориз Арборио, сметана, пилешко филе,
                        печурки, манатарки, пармезан
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7 /</span>
                        <span className="price">400 гр. / 14.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">РИЗОТО С ТЕЛЕШКО</h2>
                    <p className="description">
                        Ориз Арборио, сметана, телешко бон филе,
                        сушени домати, печурки, пресен лук,
                        пармезан
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7 /</span>
                        <span className="price">400 гр. / 17.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">РИЗОТО ВЕГЕТАРИАНО</h2>
                    <p className="description">
                        Ориз Арборио, сметана, микс от задушени
                        зеленчуци, червена киноа, кедрови ядки,
                        маскарпоне
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2, 7 /</span>
                        <span className="price">400 гр. / 13.00 лв.</span>
                    </p>
                </article>
            </section>
            <Alergens />
        </>
    )
}