import { Alergens } from "./Alergens";

export const Pizas = () => {
    return (
        <>
            <h1 className="menu-category-heading">Пицата прави живота по-добър!</h1>
            <section className="menu-category">

                <article className="menu-item">
                    <h2 className="name">Капричоза</h2>
                    <p className="description">Доматен сос, моцарела, гъби, бекон, риган</p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">400 гр. / 14.00лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Пеперони</h2>
                    <p className="description">
                        Доматен сос, моцарела, пеперони, топено сирене
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7,8 /</span>
                        <span className="price">400 гр. / 15.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Маргарита</h2>
                    <p className="description">Доматен сос, моцарела, розови домати,
                        песто</p>
                    <p className="information">
                        <span className="alergens">/ 2, 7, 8 /</span>
                        <span className="price">350 гр. / 11.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">АМЕРИКАНА</h2>
                    <p className="description">
                        Барбекю сос, моцарела, телешка кайма, чедър,
                        червен лук
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7, 8, 9 /</span>
                        <span className="price">400 гр. / 15.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Пойо</h2>
                    <p className="description">
                        Сметана, моцарела, чедър, пилешко филе, царевица, рукола
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">400 гр. / 14.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ТОПЕНО СИРЕНЕ</h2>
                    <p className="description">
                        Сметана, моцарела, топено сирене, гъби,
                        червен лук
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">400 гр. / 14.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Четири сирена</h2>
                    <p className="description">
                        Сметана, моцарела, чедър, синьо сирене, топено сирене
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">400 гр. / 15.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПРОШУТО КРУДО</h2>
                    <p className="description">
                        Сметана, моцарела, прошуто крудо, печурка,
                        синьо сирене, чери домати, песто, рукола
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2, 7, 8 /</span>
                        <span className="price">400 гр. / 15.00 лв.</span>
                    </p>
                </article>

                <article className="menu-item">
                    <h2 className="name">Пърленка с масло</h2>
                    <p className="description">Домашно изпечена пърленка, масло, чесън</p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">200 гр. / 5.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Пърленка със сирене</h2>
                    <p className="description">Домашно изпечена пърленка, краве сирене,
                        масло</p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">250 гр. / 6.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Пърленка с моцарела</h2>
                    <p className="description">Домашно изпечена пърленка, моцарела,
                        масло</p>
                    <p className="information">
                        <span className="alergens">/ 7, 8 /</span>
                        <span className="price">250 гр. / 7.00 лв.</span>
                    </p>
                </article>

                <article className="menu-item">
                    <h2 className="name">Комбинирана пърленка</h2>
                    <p className="description">Домашно изпечена пърленка, моцарела,
                        краве сирене, масло, сусам</p>
                    <p className="information">
                        <span className="alergens">/ 7, 8, 10 /</span>
                        <span className="price">300 гр. / 8.00 лв.</span>
                    </p>
                </article>
                <section className="sauces">
                    <h1 id="sauces-heading">СОСОВЕ</h1>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Keтчуп</span>
                            <span className="sauce-price">80 гр. / 1.50 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Майонеза</span>
                            <span className="sauce-price">80 гр. / 1.50 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Билкова майонеза</span>
                            <span className="sauce-price">80 гр. / 1.50 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Чеснов сос</span>
                            <span className="sauce-price">80 гр. / 1.50 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Тартар</span>
                            <span className="sauce-price">80 гр. / 1.50 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Лютив сос</span>
                            <span className="sauce-price">80 гр. / 3.00 лв.</span>
                        </p>
                    </article>
                    
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Чедър сос</span>
                            <span className="sauce-price">80 гр. / 2.50 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Барбекю</span>
                            <span className="sauce-price">80 гр. / 2.00 лв.</span>
                        </p>
                    </article>
                    <article className="sauce-item">
                        <p className="information">
                            <span className="sauce-name">Сладко чили</span>
                            <span className="sauce-price">80 гр. / 3.00 лв.</span>
                        </p>
                    </article>
                </section>
            </section>
            <Alergens />
        </>
    )
}