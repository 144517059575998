export const TopDishes = () => {
  return (
    <>
      <h1 className="menu-category-heading">Toп предложения</h1>
      <section className="menu-category">

        <article className="menu-item">
          <h2 className="name">ФЕРМЕРСКА САЛАТА</h2>
          <p className="description">
            Белени розови домати, краставици, яйчен
            катък, рукола, маслини, домашно мезе,
            брускети
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">450 гр. / 15.00 лв.</span>
          </p>
        </article>

        <article className="menu-item">
          <h2 className="name">ДЪРПАНО ТЕЛЕШКО</h2>
          <p className="description">
            Бавно готвено телешко месо, чедър сос,
            домашен чипс
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">280 гр. / 18.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ДЪРПАНО СВИНСКО</h2>
          <p className="description">
            Бавно готвено свинско месо, барбекю сос,
            пикантни кънтри картофки
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">350 гр. / 16.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name"> ПИЦА КАПРИЧОЗА</h2>
          <p className="description">
            Доматен сос, моцарела, гъби, бекон, риган
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">400 гр. / 14.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ПИЦА ПЕПЕРОНИ</h2>
          <p className="description">
            Доматен сос, моцарела, пеперони, топено
            сирене
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">400 гр. / 15.00 лв.</span>
          </p>
        </article>

        <article className="menu-item">
          <h2 className="name">ПИЛЕ ЧЕДЪР</h2>
          <p className="description">
            Пилешки филенца, чедър сос, спаначено-картофено пюре
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">350 гр. / 15.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">ДЖОЛАНЪТ НА АЛИНЕА</h2>
          <p className="description">
            Бавно готвен телешки джолан, дзадзики,
            спаначено картофено пюре
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">400 гр. / 24.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">СЬОМГА НА ПЛОЧА</h2>
          <p className="description">
            Филе от сьомга, лимонено ризото, пресен
            лук, сос тартар
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">330 гр. / 24.00 лв.</span>
          </p>
        </article>
        <article className="menu-item">
          <h2 className="name">СТАНИМАШКА ТОРТА</h2>
          <p className="description">
            Бишкотен блат, маскарпоне, Филаделфия
            крема сирене, сладко от зелени смокини,
            филирани бадеми, бял шоколад
          </p>
          <p className="information">
            <span className="alergens" />
            <span className="price">150 гр. / 8.50 лв.</span>
          </p>
        </article>


      </section>
    </>
  )
}