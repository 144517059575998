import { Alergens } from "./Alergens";

export const Starters = () => {
    return (
        <>
            <h1 className="menu-category-heading">Насладете се на малките неща от живота - Предястията</h1>
            <section className="menu-category">
                <article className="menu-item">
                    <h2 className="name">КАЛМАРИ В МАСЛО</h2>
                    <p className="description">Калмари, масло, лимон, копър, чесън</p>
                    <p className="information">
                        <span className="alergens">/ 4, 7, 11, 12 /</span>
                        <span className="price">250 гр. / 16.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ХРУПКАВИ КАЛМАРИ</h2>
                    <p className="description">Калмари в лека панировка, чеснов сос</p>
                    <p className="information">
                        <span className="alergens">/ 4, 6, 7, 8, 11, 12 /</span>
                        <span className="price">250 гр. / 16.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">СКАРИДИ</h2>
                    <p className="description">Сотирани пресни скариди, масло, лимон,
                    копър, чесън</p>
                    <p className="information">
                        <span className="alergens">/ 3, 7, 11 /</span>
                        <span className="price">200 гр. / 18.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ТЕЛЕШКИ ЕЗИК</h2>
                    <p className="description">Сотиран телешки език, манатарки,
                    печурка, масло, трюфелово олио</p>
                    <p className="information">
                        <span className="alergens">/ 7 /</span>
                        <span className="price">250 гр. / 18.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ХРУПКАВИ ПИЛЕШКИ
                    ПРЪЧИЦИ</h2>
                    <p className="description">
                    Пилешко филе, панко японска панировка,
                    чеснов сос
                    </p>
                    <p className="information">
                        <span className="alergens">/ 6, 7, 8, 9, 11/</span>
                        <span className="price">300 гр. / 12.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ХРУПКАВИ ТОПЕНИ СИРЕНЦА</h2>
                    <p className="description">Топени сиренца, панко японска панировка,
                    сладко от боровинки</p>
                    <p className="information">
                        <span className="alergens">/ 6, 7, 8 /</span>
                        <span className="price">250 гр. / 13.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ГЪБКИ В МАСЛО</h2>
                    <p className="description">Печурки, манатарки, масло</p>
                    <p className="information">
                        <span className="alergens">/ 7 /</span>
                        <span className="price">250 гр. / 11.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">КАРТОФЕНИ ТРЮФЕЛИ</h2>
                    <p className="description">
                    Мачкани картофи, крема сирене, пармезан,
                    срирача майонеза, билкова коричка
                    </p>
                    <p className="information">
                        <span className="alergens">/ 6, 7, 8 /</span>
                        <span className="price">250 гр. / 11.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">
                    МИКС РАЗЯДКИ С ЧЕСНОВА
                    ПЪРЛЕНКА
                    </h2>
                    <p className="description">Кьопоолу, яйчен катък, дзадзики, лютеница,
                    домашна чеснова пърленка</p>
                    <p className="information">
                        <span className="alergens">/ 6, 7, 8, 9, 11, 12, 14 /</span>
                        <span className="price">300 гр. / 12.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ЗЕЛЕНЧУЦИ НА ПЛОЧА</h2>
                    <p className="description">Тиквички, моркови, зелени чушки, червени
                    чушки, червен лук, рукола, песто, магданоз</p>
                    <p className="information">
                        <span className="alergens">/ 2 /</span>
                        <span className="price">330 гр. / 10.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ДЪРПАНО ТЕЛЕШКО</h2>
                    <p className="description">Бавно готвено телешко месо, чедър сос,
                    домашен чипс</p>
                    <p className="information">
                        <span className="alergens">/ 7, 9 /</span>
                        <span className="price">280 гр. / 18.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ДЪРПАНО СВИНСКО</h2>
                    <p className="description">Бавно готвено свинско месо, барбекю сос,
                    пикантни кънтри картофки</p>
                    <p className="information">
                        <span className="alergens">/ 14 /</span>
                        <span className="price">350 гр. / 16.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПЪРЖЕНИ КАРТОФИ</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens"></span>
                        <span className="price">250 гр. / 6.00 лв.</span>
                    </p>
                </article>

                <article className="menu-item">
                    <h2 className="name">ПЪРЖЕНИ КАРТОФИ СЪС СИРЕНЕ</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens"> / 7 /</span>
                        <span className="price">300 гр. / 7.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПИКАНТНИ КЪНТРИ КАРТОФКИ</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens"></span>
                        <span className="price">250 гр. / 6.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПИКАНТНИ КЪНТРИ КАРТОФКИ СЪС СИРЕНЕ</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens"> / 7 /</span>
                        <span className="price">300 гр. / 7.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ДОМАШЕН ЧИПС</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens"></span>
                        <span className="price">250 гр. / 6.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ДОМАШЕН ЧИПС СЪС СИРЕНЕ</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens"> / 7 /</span>
                        <span className="price">300 гр. / 7.50 лв.</span>
                    </p>
                </article>
                
                
            </section>
            <Alergens />
        </>
    )
}