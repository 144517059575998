import { Alergens } from "./Alergens";
export const Salads = () => {
    return (
        <>
            <h1 className="menu-category-heading">Салатите - където здравословното се среща с вкусното</h1>
            <section className="menu-category">

                <article className="menu-item">
                    <h2 className="name">КАПРЕЗЕ</h2>
                    <p className="description">
                        Белени розови домати, прясна моцарела, кедрови ядки, рукола, песто, винегрет от
                        нар
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2,7 /</span>
                        <span className="price">270 гр. / 13.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">МОРСКИ ДАР</h2>
                    <p className="description">
                        Микс от свежи салати, чери домати, хрупкави калмари и скариди, медено-горчичен дресинг
                    </p>
                    <p className="information">
                        <span className="alergens">/ 3,4,8,12 /</span>
                        <span className="price">300 гр. / 15.00 лв.</span>
                    </p>
                </article>

                <article className="menu-item">
                    <h2 className="name">ШОПСКА</h2>
                    <p className="description">
                        Розови домати, краставици, краве сирене, домашни печени чушки, червен лук,
                        маслини, магданоз, люта чушка
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7 /</span>
                        <span className="price">350 гр. / 11.00 лв.</span>
                    </p>
                </article>

                <article className="menu-item">
                    <h2 className="name">ГРЪЦКА ХОРИАТИКИ</h2>
                    <p className="description">
                        Розови домати, краставици, мариновано сирене Фета, червен лук, зелена чушка, маслини Каламата
                    </p>
                    <p className="information">
                        <span className="alergens">/ 7 /</span>
                        <span className="price">350 гр. / 13.00 лв.</span>
                    </p>
                </article>

                <article className="menu-item">
                    <h2 className="name">ДЗАДЗИКИ</h2>
                    <p className="description">
                        Краставици, цедено кисело мляко, орехи, копър, чесън
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2,7 /</span>
                        <span className="price">250 гр. / 10.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ЦЕЗАР С ПИЛЕШКО</h2>
                    <p className="description">
                        Айсберг, чери домати, печено пилешко филе,
                        крутони, Цезар дресинг, пармезан
                    </p>
                    <p className="information">
                        <span className="alergens">/ 6,7,8,9,12 /</span>
                        <span className="price">300 гр. / 14.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ПРОШУТО КРУДО</h2>
                    <p className="description">
                        Микс от зелени салати, чипс от прошуто крудо,
                        овче сирене, синьо сирене, чедър, филирани
                        бадеми, зелени смокини, винегрет от нар
                    </p>
                    <p className="information">
                        <span className="alergens">/ 2,7 /</span>
                        <span className="price">350 гр. / 15.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">ХРУПКАВА БЯЛА РИБА</h2>
                    <p className="description">
                        Микс от зелени салати, червена
                        киноа, хрупкава бяла риба, чери
                        домати, медено-горчичен дресинг
                    </p>
                    <p className="information">
                        <span className="alergens">/ 5,6,8,9,12,14 /</span>
                        <span className="price">350 гр./ 15.00 лв.</span>
                    </p>
                </article>


                <article className="menu-item">
                    <h2 className="name">ФЕРМЕРСКА</h2>
                    <p className="description">
                        Белени розови домати, краставици, яйчен
                        катък, рукола, маслини, домашно мезе,
                        брускети
                    </p>
                    <p className="information">
                        <span className="alergens">/ 6,7,8 /</span>
                        <span className="price">450 гр. / 15.00 лв.</span>
                    </p>
                </article>
            </section>
            <Alergens />

        </>
    );
}