import { Alergens } from "./Alergens";

export const Deserts = () => {
    return (
        <>
            <h1 className="menu-category-heading">Десерти</h1>
            <section className="menu-category">
                <article className="menu-item">
                    <h2 className="name">ШОКОЛАДОВА ТОРТА</h2>
                    <p className="description">Какаов блат, течен шоколад, маскарпоне,
                        Филаделфия крема сирене</p>
                    <p className="information">
                        <span className="alergens">/ 2, 6, 7, 8 /</span>
                        <span className="price">150 гр. / 8.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">СТАНИМАШКА ТОРТА</h2>
                    <p className="description">Бишкотен блат, маскарпоне, Филаделфия
                        крема сирене, сладко от зелени смокини,
                        филирани бадеми, бял шоколад </p>
                       <p>* Съставът може да варира според сезона</p>
                    <p className="information">
                        <span className="alergens">/ 2, 7, 8 /</span>
                        <span className="price">150 гр. / 8.50 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Топка домашен сладолед</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span className="alergens">/ 2, 7 /</span>
                        <span className="price">80 гр. / 3.00 лв.</span>
                    </p>
                </article>
                <article className="menu-item">
                    <h2 className="name">Десерт на деня</h2>
                    <p className="description"></p>
                    <p className="information">
                        <span id="daily-dessert">Попитайте вашият сервитьор</span>
                        <span className="price" />
                    </p>
                </article>
            </section>
            <Alergens />
        </>
    )
}