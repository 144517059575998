export const Drinks = () => {
  return (
    <>
      <h1 className="menu-category-heading">Напитки</h1>
      <section className="drinks">
        <section className="hot-drinks">
          <h1>Toпли напитки</h1>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Кафе еспресо</span>
              <span className="drink-price">60 мл. / 2.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Кафе без кофеин</span>
              <span className="drink-price">300 мл. / 3.20 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Виенско кафе</span>
              <span className="drink-price">120 мл. / 3.80 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Kaпучино</span>
              <span className="drink-price">180 мл. / 4.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Капучино без кофеин</span>
              <span className="drink-price">180 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Лате</span>
              <span className="drink-price">200 мл. / 4.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Лешниково лате</span>
              <span className="drink-price">230 мл. / 5.30 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Карамелено лате</span>
              <span className="drink-price">230 мл. / 5.30 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Мокачино</span>
              <span className="drink-price">180 мл. / 5.30 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Горещ шоколад</span>
              <span className="drink-price">200 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Чай Даман</span>
              <span className="drink-price">500 мл. / 5.00 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Каничка мляко, малка</span>
              <span className="drink-price">40 мл. / 0.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Kаничка мляко, голяма</span>
              <span className="drink-price">100 мл. / 1.20 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Сметана, суха / течна</span>
              <span className="drink-price">7.5 мл. / 0.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Бита сметана</span>
              <span className="drink-price">20 мл. / 0.80 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Мед</span>
              <span className="drink-price">15 мл. / 0.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Ирландско кафе (с уиски)</span>
              <span className="drink-price">180 мл. / 7.50 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Ирландско лате (с Бейлис)</span>
              <span className="drink-price">200 мл. / 5.50 лв.</span>
            </p>
          </article>
        </section>
        <section className="cold-drikns">
          <h1>Студени напитки</h1>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Фрапе, черно</span>
              <span className="drink-price">300 мл. / 4.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Фрапе, бяло</span>
              <span className="drink-price">300 мл. / 4.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Фреш (портoкал, грейпфрут)</span>
              <span className="drink-price">300 мл. / 5.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Домашна лимонада, чаша</span>
              <span className="drink-price">300 мл. / 5.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Домашна лимонада, кана</span>
              <span className="drink-price">1000 мл. / 13.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Студен чай</span>
              <span className="drink-price">250 мл. / 3.30 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Капи</span>
              <span className="drink-price">250 мл. / 3.30 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Кока кола продукти</span>
              <span className="drink-price">250 мл. / 2.80 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Швепс тоник</span>
              <span className="drink-price">250 мл. / 2.80 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Швепс сода</span>
              <span className="drink-price">250 мл. / 2.50 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Перие</span>
              <span className="drink-price">330 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Сан Пелегрино</span>
              <span className="drink-price">750 мл. / 7.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Банкя, минерална вода</span>
              <span className="drink-price">400 мл. / 2.70 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Банкя, минерална вода</span>
              <span className="drink-price">1 л. / 4.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Аква Пана</span>
              <span className="drink-price">750 мл. / 7.90 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Айрян, чаша</span>
              <span className="drink-price">300 мл. / 2.60 лв.</span>
            </p>
          </article>

          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Айрян, кана</span>
              <span className="drink-price">1 л. / 6.60 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Ред бул</span>
              <span className="drink-price">250 мл. / 6.50 лв.</span>
            </p>
          </article>
          <article className="drink-item">
            <p className="information">
              <span className="drink-name">Кока кола кен</span>
              <span className="drink-price">330 мл. / 3.50 лв.</span>
            </p>
          </article>
        </section>
      </section>
      <section className="alcohol">
        <section className="whiskey">
          <h1>Уиски</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Джим Бийм</span>
              <span className="alcohol-price">50 мл. / 5.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Булет</span>
              <span className="alcohol-price">50 мл. / 9.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Джеймисън</span>
              <span className="alcohol-price">50 мл. / 5.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Пади</span>
              <span className="alcohol-price">50 мл. / 5.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бушмилс</span>
              <span className="alcohol-price">50 мл. / 5.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бушмилс черен</span>
              <span className="alcohol-price">50 мл. / 6.90 лв.</span>
            </p>
          </article><article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Джак Даниелс</span>
              <span className="alcohol-price">50 мл. / 6.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Джентълмен Джак</span>
              <span className="alcohol-price">50 мл. / 9.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Чивас регал</span>
              <span className="alcohol-price">50 мл. / 7.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Мънки Шоулдър, скоч</span>
              <span className="alcohol-price">50 мл. / 8.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Джони Уокър</span>
              <span className="alcohol-price">50 мл. / 5.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Джони Уокър черен</span>
              <span className="alcohol-price">50 мл. / 8.50 лв.</span>
            </p>
          </article>
        </section>
        <section className="aperitive">
          <h1>Аперитив / Диджестив</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Мартини, бианко</span>
              <span className="alcohol-price">100 мл. / 7.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Мартини, сухо</span>
              <span className="alcohol-price">100 мл. / 7.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Кампари</span>
              <span className="alcohol-price">100 мл. / 8.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Йегермайстер</span>
              <span className="alcohol-price">50 мл. / 5.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Йегермайстер, шот</span>
              <span className="alcohol-price">30 мл. / 3.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бейлис</span>
              <span className="alcohol-price">50 мл. / 6.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Мента Пещера</span>
              <span className="alcohol-price">50 мл. / 3.00 лв.</span>
            </p>
          </article>
        </section>
        <section className="vodka">
          <h1>Водка</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Финландия</span>
              <span className="alcohol-price">50 мл. / 4.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Абсолют</span>
              <span className="alcohol-price">50 мл. / 4.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Смирноф</span>
              <span className="alcohol-price">50 мл. / 4.00 лв.</span>
            </p>
          </article>

          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Грей Гус</span>
              <span className="alcohol-price">50 мл. / 12.50 лв.</span>
            </p>
          </article>
        </section>
        <section className="gin">
          <h1>Джин</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Гордънс</span>
              <span className="alcohol-price">50 мл. / 4.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Гордънс розов</span>
              <span className="alcohol-price">50 мл. / 4.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бийфийтър</span>
              <span className="alcohol-price">50 мл. / 4.50 лв.</span>
            </p>
          </article>
        </section>
        <section className="rum">
          <h1>Ром</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Капитан Морган, бял</span>
              <span className="alcohol-price">50 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Капитан Морган, спайсд</span>
              <span className="alcohol-price">50 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Капитан Морган, тъмен</span>
              <span className="alcohol-price">50 мл. / 4.90 лв.</span>
            </p>
          </article>
        </section>
        <section className="konqk">
          <h1>Коняк</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Метакса 5</span>
              <span className="alcohol-price">50 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Метакса 7</span>
              <span className="alcohol-price">50 мл. / 6.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Корвозие</span>
              <span className="alcohol-price">50 мл. / 11.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Реми Мартен</span>
              <span className="alcohol-price">50 мл. / 13.90 лв.</span>
            </p>
          </article>
        </section>
        <section className="beer">
          <h1>Бира</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Стела Артоа бутилка</span>
              <span className="alcohol-price">500 мл. / 4.60 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Стела Артоа наливна</span>
              <span className="alcohol-price">330 мл. / 4.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Стела Артоа наливна</span>
              <span className="alcohol-price">500 мл. / 5.60 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Стела Артоа безалкохолна</span>
              <span className="alcohol-price">330 мл. / 4.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Старопрамен</span>
              <span className="alcohol-price">500 мл. / 4.40 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Мадри</span>
              <span className="alcohol-price">500 мл. / 4.40 лв.</span>
            </p>
          </article>

          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Хугарден</span>
              <span className="alcohol-price">330 мл. / 6.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Корона</span>
              <span className="alcohol-price">330 мл. / 6.50 лв.</span>
            </p>
          </article>
        </section>
        <section className="tequila">
          <h1>Текила</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Хосе Куерво Голд</span>
              <span className="alcohol-price">50 мл. / 5.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Хосе Куерво Силвър</span>
              <span className="alcohol-price">50 мл. / 5.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Хосе Куерво Голд - шот</span>
              <span className="alcohol-price">30 мл. / 3.90 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Хосе Куерво Силвър - шот</span>
              <span className="alcohol-price">30 мл. / 3.90 лв.</span>
            </p>
          </article>
        </section>
        <section className="rakiq">
          <h1>Ракия</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Старосел, домашна</span>
              <span className="alcohol-price">50 мл. / 4.60 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Троянска сливова</span>
              <span className="alcohol-price">50 мл. / 4.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бургас 63</span>
              <span className="alcohol-price">50 мл. / 4.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бургас 63 барел</span>
              <span className="alcohol-price">50 мл. / 5.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Стралжанска мускатова</span>
              <span className="alcohol-price">50 мл. / 4.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Поморийска мускатова</span>
              <span className="alcohol-price">50 мл. / 4.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Сливенска перла</span>
              <span className="alcohol-price">50 мл. / 4.50 лв.</span>
            </p>
          </article>
        </section>
        <section className="anason">
          <h1>Анасонови напитки</h1>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Пломари</span>
              <span className="alcohol-price">50 мл. / 4.20 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Пломари</span>
              <span className="alcohol-price">200 мл. / 14.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Адоло Пломари</span>
              <span className="alcohol-price">50 мл. / 6.30 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Адоло Пломари</span>
              <span className="alcohol-price">200 мл. / 21.00 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бабадзим</span>
              <span className="alcohol-price">50 мл. / 4.20 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Бабадзим</span>
              <span className="alcohol-price">200 мл. / 14.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Узо 12</span>
              <span className="alcohol-price">50 мл. / 4.20 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Узо 12</span>
              <span className="alcohol-price">200 мл. / 15.50 лв.</span>
            </p>
          </article>
          <article className="alcohol-item">
            <p className="information">
              <span className="alcohol-name">Перно Париж</span>
              <span className="alcohol-price">50 мл. / 4.90 лв.</span>
            </p>
          </article>
        </section>
        {/* <h1 id="wines">Вина</h1> */}
        <section className="white-wines">
          <h1>Бяло вино 0.75 л</h1>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">ВИОРИКА - ПУРКАРИ </span>
              <span className="wine-price">38.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: цветя от акация, босилек</p>
          <p className="wine-desc">Вкус: балансиран и мек с фин аромат на мускат</p>

          <article className="wine-item">
            <p className="information">
              <span className="wine-name">ПИНО ГРИДЖО - ПУРКАРИ</span>
              <span className="wine-price">36.00 лв.</span>
            </p>
          </article>

          <p className="wine-aroma">Аромат: лимон, ябълка, круша</p>
          <p className="wine-desc">Вкус: свеж, плодов и хармоничен</p>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">ВЕРМЕНТИНО - СТЕЛИЪН </span>
              <span className="wine-price">37.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат:  лимон, лайм, зелена ябълка, праскова и круша</p>
          <p className="wine-desc">Вкус: флорални и билкови нюанси</p>

          <article className="wine-item">
            <p className="information">
              <span className="wine-name">СОВИНЬОН БЛАН - СТЕЛИЪН</span>
              <span className="wine-price">36.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: тропически плодове и свежи цветя</p>
          <p className="wine-desc">Вкус:  свеж и балансиран, земни нюанси</p>
        </section>
        <section className="red-wines">
          <h1>Червено вино 0.75 л</h1>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">ПИНО НОАР - ПУРКАРИ </span>
              <span className="wine-price">36.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: ягода, малина, череша</p>
          <p className="wine-desc">Вкус: завършен, продължителен,
            кадифен</p>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">КАБЕРНЕ СОВИНЬОН - ПУРКАРИ</span>
              <span className="wine-price">36.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: черни плодове, екзотични
            подправки</p>
          <p className="wine-desc">Вкус: шоколад, зрели плодове, черен
            пипер, стафиди</p>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">СИРА - СТЕЛИЪН</span>
              <span className="wine-price">37.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: черни горски плодове, кожа,
            подправки</p>
          <p className="wine-desc">Вкус: меко, сочно тяло, кадифени
            танини, дълъг финал</p>
        </section>
        <section className="rose">
          <h1>Розе 0.75 л</h1>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">РОЗЕ - ПУРКАРИ </span>
              <span className="wine-price">38.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: червени и костилкови плодове, ягоди</p>
          <p className="wine-desc">Вкус:  свежи и сочни нотки на червена
            боровинка, праскова, горски плодове</p>

          <article className="wine-item">
            <p className="information">
              <span className="wine-name">РОЗЕ - СТЕЛИЪН </span>
              <span className="wine-price">37.00 лв.</span>
            </p>
          </article>
          <p className="wine-aroma">Аромат: свеж, комплексен, горски плодове,
            вишна, грейпфрут</p>
          <p className="wine-desc">Вкус: експлозивен, завладяващ,
            продължителен и свеж финал</p>

        </section>
        <section className="wine">
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">Бяло, червено, розе</span>
              <span className="wine-price">200 мл. / 7.00 лв.</span>
            </p>
          </article>
          <article className="wine-item">
            <p className="information">
              <span className="wine-name">Бяло, червено, розе  </span>
              <span className="wine-price">500 мл./ 15.00 лв.</span>
            </p>
          </article>
        </section>
        <section className="coctails">
          <h1>Класически коктейли</h1>
          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">МАРГАРИТА </span>
              <span className="coctail-price">10.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Хосе Куерво силвър текила,
            трипъл сек, сок от лайм, сол,
            лед;  250 мл.
          </p>
          <p className="coctail-desc">*добавете вкус на ягода или
            манго- 1 лв</p>

          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">МОХИТО </span>
              <span className="coctail-price">9.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Капитан Морган бял ром, сок
            от лайм, бар сироп, листа от
            мента, сода, лед; 250 мл.</p>
          <p className="coctail-desc">*добавете вкус на ягода или
            манго - 1 лв</p>

          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">ДАЙКИРИ</span>
              <span className="coctail-price">9.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Капитан Морган бял ром,
            лимонов сок, бар сироп,
            блендиран лед, избор на вкус
            ягода или манго; 250 мл.
          </p>
          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">АПЕРОЛ ШПРИЦ</span>
              <span className="coctail-price">9.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Аперол, просеко, портокал,
            сода, лед; 250 мл.
          </p>
          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">ПИНК ТОНИК</span>
              <span className="coctail-price">9.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Гордънс розов джин, розов
            тоник, ягода, лед; 250 мл.
          </p>
          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">САНГРИЯ ПЪПЕШ</span>
              <span className="coctail-price">9.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Бяло вино, ликьор зелен пъпеш,
            сода, бар сироп, сок портокал,
            коктейлни черешки,
            портокал, лайм, лед; 250 мл.
          </p>
          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">ТВЪРДА ЛИМОНАДА</span>
              <span className="coctail-price">9.00 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma">Лимонов сок, бар сироп, сода,
            ликьор зелен пъпеш, лед; 250 мл.
          </p>
        </section>

        <section className="coctails">
          <h1>Моктейли</h1>
          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">МОХИТО МОКТЕЙЛ </span>
              <span className="coctail-price"> 300 мл. / 6.90 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma"> Сок от лайм, сода, бар
            сироп, парченца лайм,
            мента, лед
          </p>

          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">БРАЗИЛСКА ЛИМОНАДА </span>
              <span className="coctail-price"> 300 мл. / 6.90 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma"> Сок от лайм, пюре от
            ягоди, мляко, блендиран лед
          </p>

          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">ДАЙКИРИ МОКТЕЙЛ </span>
              <span className="coctail-price"> 300 мл. / 6.90 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma"> Пюре от ягоди, лимонов
            сок, бар сироп, лайм,
            блендиран лед
          </p>

          <article className="coctail-item">
            <p className="information">
              <span className="coctail-name">СЪНРАЙЗ МОКТЕЙЛ </span>
              <span className="coctail-price"> 300 мл. / 6.90 лв.</span>
            </p>
          </article>
          <p className="coctail-aroma"> Портокалов сок,
            портокал, коктейлни
            черешки, гренадин, лед
          </p>
        </section>
      </section>
    </>
  )
}